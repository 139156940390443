.wrapper {
  display: flex;
  align-items: center;
  position: relative;
  /* border: 1px solid red; */
  background-color: '#FFFFFF';
}

.barContainer {
  flex: 1;
  background-color: '#FFFFFF';
  border-radius: 12px;
  overflow: hidden; /* Crucial part */
  box-shadow: 0px 0px 4px grey;
}

.textValue {
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  position: absolute;
  font-size: 18px;
  color: #334149;
  font-weight: 600;
}
.logo {
  height: 90px;
  width: 90px;
  position: absolute;
  display: flex;
  right: 0;
  bottom: 0;
}

.fillerBackground {
  height: inherit;
  transition: 'width 2s ease-i-out';
}

.filler {
  transition: 'width 2s ease-i-out';
  overflow: hidden;

  height: 40px;
}
