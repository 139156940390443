.congratulation-screen {
  /* min-height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f6; /* Light gray background */
  border-radius: 10px;
}

.congratulation-content {
  background-color: #ffffff; /* White background */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */
  text-align: center;
}

.congratulation-content h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 16px;
  color: green;
}
