.infoTable table,
.infoTable th,
.infoTable td {
  border: 1px solid;
  border-color: #ff6760;
  border-collapse: collapse;
}

.infoTable th {
  font-size: '18px';
  font-weight: 600;
}

.menu-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-trigger {
  background: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ff6760;
  padding: 0px 0px 0px 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: 1px solid #ff6760;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto; /* Strictly for positioning */
}

.menu-Icon {
  width: 35px;
  height: 35px;
  background-color: #ff6760;
  color: '#FFFFFF';
  vertical-align: middle;
  padding-top: 5px;
}

.menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menu-trigger span {
  font-weight: 700;
  vertical-align: middle;
  font-size: 14px;
  margin: 0 10px;
}

.menu-trigger img {
  border-radius: 90px;
}

.menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 50px;
  right: 0;
  width: 200px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menu.active {
  opacity: 2;
  visibility: visible;
  transform: translateY(0);
  z-index: 10;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
}

.list {
  text-decoration: none;
  color: #333333;
  padding: 10px 20px;
  display: block;
  cursor: pointer;
}
